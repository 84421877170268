import { appendAsyncScript } from './append-async-script';
import {
  FACEBOOK_PIXEL_ID,
  INTERCOM_APP_ID,
  LINKEDIN_INSIGHT_ID,
  GOOGLE_ADS_ID,
} from './environment';

export const ThirdPartyScripts = () => {
  /**
   * https://developers.facebook.com/docs/meta-pixel/get-started/?locale=fr_FR
   */
  const initFacebookPixel = () => {
    if (!FACEBOOK_PIXEL_ID || window.fbq) {
      return;
    }

    // eslint-disable-next-line func-names
    window.fbq = function () {
      if (window.fbq.callMethod) {
        // eslint-disable-next-line prefer-spread,prefer-rest-params
        window.fbq.callMethod.apply(window.fbq, arguments);
      } else {
        // eslint-disable-next-line prefer-rest-params
        window.fbq.queue.push(arguments);
      }
    };

    if (!window._fbq) {
      window._fbq = window.fbq;
    }

    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    appendAsyncScript(`https://connect.facebook.net/en_US/fbevents.js`);

    window.fbq('init', FACEBOOK_PIXEL_ID);
    window.fbq('consent', 'grant');
    window.fbq('track', 'PageView');
  };

  /**
   * https://www.linkedin.com/help/lms/answer/a418880
   */
  const initLinkedInInsight = () => {
    if (!LINKEDIN_INSIGHT_ID) {
      return;
    }

    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(LINKEDIN_INSIGHT_ID);

    if (!window.lintrk) {
      // eslint-disable-next-line func-names
      window.lintrk = function () {
        // eslint-disable-next-line prefer-rest-params
        window.lintrk.q.push(arguments);
      };

      window.lintrk.q = [];
    }

    appendAsyncScript(`https://snap.licdn.com/li.lms-analytics/insight.min.js`);
  };

  /**
   * https://developers.intercom.com/installing-intercom/web/installation/
   */
  const initIntercom = () => {
    if (!INTERCOM_APP_ID) {
      return;
    }

    window.intercomSettings = {
      app_id: INTERCOM_APP_ID,
    };

    if (window.Intercom && typeof window.Intercom === 'function') {
      window.Intercom('reattach_activator');
      window.Intercom('update', window.intercomSettings);
      return;
    }

    // eslint-disable-next-line func-names
    window.Intercom = function () {
      // eslint-disable-next-line prefer-rest-params
      window.Intercom.c(arguments);
    };
    window.Intercom.q = [];
    window.Intercom.c = (args) => {
      window.Intercom.q.push(args);
    };

    appendAsyncScript(`https://widget.intercom.io/widget/${INTERCOM_APP_ID}`);
  };

  /**
   * https://developers.google.com/tag-platform/gtagjs/configure?hl=fr
   */
  const initGoogleAds = () => {
    if (!GOOGLE_ADS_ID) {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    // eslint-disable-next-line func-names
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };

    window.gtag('js', new Date());
    window.gtag('config', GOOGLE_ADS_ID);

    appendAsyncScript(
      `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ADS_ID}`
    );
  };

  return {
    initLinkedInInsight,
    initFacebookPixel,
    initIntercom,
    initGoogleAds,
  };
};
