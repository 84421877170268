// BREAKPOINTS
export const BREAKPOINT_MOBILE = 467;
export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_LARGE_TABLET = 992;
export const BREAKPOINT_SMALL_DESKTOP = 1260;

// SOCIAL NETWORKS
export const INSTAGRAM = 'https://www.instagram.com/convelio/';
export const LINKEDIN = 'https://www.linkedin.com/company/convelio/about/';
export const FACEBOOK = 'https://www.facebook.com/Convelio-756697587853828/';

// SCROLL
export const PIXELS_FOR_NAVBAR_SCROLL_STATE = 100;
export const PIXELS_TO_SCROLL_TO_SHOW_SCROLL_TO_TOP_BUTTON = 4000;

// TIMEOUT
export const SCROLL_TIMEOUT = 1000;
export const SCROLL_TO_NODE_TIME = 400;

// CARDS
export const WIDTH_SLIDING_CARD = 520;
export const MARGIN_RIGHT_SLIDING_CARD = 24;

// FILTERS
export const ALL_FILTERS = {
  id: 'ALL_FILTERS_ID',
  title: 'All',
};

// DEVICES
export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const TABLET_LARGE = 'tablet large';
export const DESKTOP_SMALL = 'desktop small';
export const DESKTOP = 'desktop';

// TAG MANAGEMENT EVENT
export const USE_BUTTON = 'Use button';
export const SUBSCRIBE_TO_NEWSLETTER = 'Subscribe to newsletter';
export const UPDATE_NEWSLETTER_SUBSCRIPTIONS =
  'Update newsletter subscriptions';
export const UNSUBSCRIBE_ALL_NEWSLETTER = 'Unsubscribe all newsletter';
export const PROVIDE_UNSUBSCRIBE_REASON = 'Provide unsubscribe reason';
export const CHANGE_LANGUAGE = 'Change language';

// EMAIL PREFERENCES
export const MANY_EMAILS = 'too many emails';
export const NOT_INTERESTED = 'shipment emails only';
export const OTHER = 'other';
export const SWITCH_PREFERENCES = 'SWITCH_PREFERENCES';
export const SWITCH_PREFERENCE = 'SWITCH_PREFERENCE';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const SET_STEP = 'SET_STEP';

// NAVBAR
export const SWITCH_NAVBAR_STATE = 'SWITCH_NAVBAR_STATE';
export const SET_NAVBAR_STATE_PROP = 'SET_NAVBAR_STATE_PROP';
