import React, { useReducer } from 'react';

import { NavbarContext } from '../navbar';

import {
  SWITCH_NAVBAR_STATE,
  SET_NAVBAR_STATE_PROP,
} from '../../utils/variables';

export function NavbarProvider({ children }) {
  const initialState = {
    activeNavlink: {},
    activeSubNavlink: {},
    isNavbarTransparent: false,
    isBurgerMenu: false,
  };

  function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
      case SWITCH_NAVBAR_STATE:
        return {
          ...state,
          [payload]: !state[payload],
        };
      case SET_NAVBAR_STATE_PROP:
        const { prop, value } = payload;
        return {
          ...state,
          [prop]: value,
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <NavbarContext.Provider value={{ state, dispatch }}>
      {children}
    </NavbarContext.Provider>
  );
}
