import React, { useState } from 'react';

import { SitePathsContext } from '../sitePaths';

export const SitePathsProvider = ({ children }) => {
  const [sitePaths, setSitePaths] = useState([]);
  const [pageContentfulId, setPageContentfulId] = useState([]);
  const [allCurrentPaths, setAllCurrentPaths] = useState([]);
  const [otherLanguagesCurrentPath, setOtherLanguagesCurrentPaths] = useState(
    []
  );
  const [currentPath, setCurrentPath] = useState('');

  return (
    <SitePathsContext.Provider
      value={{
        sitePaths,
        setSitePaths,
        allCurrentPaths,
        setAllCurrentPaths,
        currentPath,
        setCurrentPath,
        otherLanguagesCurrentPath,
        setOtherLanguagesCurrentPaths,
        pageContentfulId,
        setPageContentfulId
      }}
    >
      {children}
    </SitePathsContext.Provider>
  );
};
