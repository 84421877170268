import { appendAsyncScript } from './append-async-script';
import { AXEPTIO_CLIENT_ID } from './environment';
import { TagManagement } from './tag-management';
import { ThirdPartyScripts } from './third-party-scripts';

export const CookieConsent = () => {
  const {
    initFacebookPixel,
    initIntercom,
    initLinkedInInsight,
    initGoogleAds,
  } = ThirdPartyScripts();
  const { enableTagManagement, disableTagManagement } = TagManagement();
  const AxeptioVendor = {
    GoogleAds: 'google_ads',
    PostHog: 'posthog',
    Datadog: 'Datadog',
    Stripe: 'Stripe',
    FacebookPixel: 'facebook_pixel',
    Linkedin: 'linkedin',
    Intercom: 'intercom',
  };

  // eslint-disable-next-line class-methods-use-this
  const openPopUp = () => {
    window.openAxeptioCookies?.();
  };

  // eslint-disable-next-line class-methods-use-this
  const initAxeptio = () => {
    window._axcb = window._axcb || [];
    window.axeptioSettings = {
      clientId: AXEPTIO_CLIENT_ID,
      userCookiesDomain: 'convelio.com',
      openCookiesWidgetIfVendorsMismatch: true,
      triggerGTMEvents: false,
      googleConsentMode: {
        default: [
          {
            analytics_storage: 'denied',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            wait_for_update: 500,
          },
          {
            region: ['US'],
            analytics_storage: 'granted',
            ad_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            wait_for_update: 500,
          },
        ],
      },
    };

    appendAsyncScript('//static.axept.io/sdk-slim.js');
  };

  const onCookieComplete = () => {
    window._axcb = window._axcb || [];
    window._axcb.push((sdk) => {
      sdk.on('cookies:complete', (choices) => {
        if (AxeptioVendor.Linkedin in choices) {
          initLinkedInInsight();
        }

        if (AxeptioVendor.Intercom in choices) {
          initIntercom();
        }

        if (AxeptioVendor.FacebookPixel in choices) {
          initFacebookPixel();
        }

        if (AxeptioVendor.PostHog in choices) {
          enableTagManagement();
        } else {
          disableTagManagement();
        }

        if (AxeptioVendor.GoogleAds in choices) {
          initGoogleAds();
        }
      });
    });
  };

  const setCookiesVersion = (lang = 'en') => {
    window._axcb = window._axcb || [];
    window._axcb.push((sdk) => {
      sdk.setCookiesVersion(`convelio-${lang === 'fr' ? 'fr' : 'en'}`);
    });
  };

  const initCookieConsent = () => {
    initAxeptio();
    onCookieComplete();
  };

  return { openPopUp, initCookieConsent, setCookiesVersion };
};
