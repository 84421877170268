import React, { useState, useEffect } from 'react';
import { CookieConsent } from '../../utils/cookie-consent';
import {
  getRemainingLanguages,
  LANG_KEY_TO_LANGUAGE_MAPPING,
} from '../../utils/languages';

import { LanguageContext } from '../language';
import languages from '../../data/languages';

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState('en');
  const [langKey, setLangKey] = useState('en');
  const [langs] = useState(languages.langs);
  const [remainingLanguages, setRemainingLanguages] = useState([]);
  const [languageHasChanged] = useState(false);
  const { defaultLangKey } = languages;
  const { setCookiesVersion } = CookieConsent();

  useEffect(() => {
    setLanguage(LANG_KEY_TO_LANGUAGE_MAPPING.get(langKey));
    setRemainingLanguages(getRemainingLanguages(langs, langKey));
    setCookiesVersion(langKey);
  }, [langKey]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLangKey,
        langKey,
        langs,
        remainingLanguages,
        defaultLangKey,
        languageHasChanged,
        getRemainingLanguages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
