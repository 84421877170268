import { POSTHOG_API_KEY } from './environment';

export const TagManagement = () => {
  const initPostHog = () => {
    // Snippet from https://posthog.com/docs/libraries/js
    /* eslint-disable */
    !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
    /* eslint-enable */

    window.posthog.init(POSTHOG_API_KEY, {
      api_host: 'https://eu.posthog.com',
      autocapture: false,
      persistence: 'localStorage+cookie',
    });

    // Initial PostHog page view
    window.posthog.capture(
      '$pageview',
      window.posthog_page_view_properties || undefined
    );
  };

  const sendPageView = (properties) => {
    if (window.posthog) {
      window.posthog.capture('$pageview', properties);
    } else if (properties) {
      window.posthog_page_view_properties = properties;
    }
  };

  const sendEvent = (name, properties) => {
    if (window.posthog) {
      window.posthog.capture(name, properties);
    }
  };

  const enableTagManagement = () => {
    if (window.posthog) {
      window.posthog.opt_in_capturing();
    }
  };

  const disableTagManagement = () => {
    if (window.posthog) {
      window.posthog.opt_out_capturing();
    }
  };

  return {
    initTagManagement: initPostHog,
    enableTagManagement,
    disableTagManagement,
    sendEvent,
    sendPageView,
  };
};
