exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contentful-article-node-locale-blog-blogpost-contentful-article-slug-js": () => import("./../../../src/pages/{ContentfulArticle.node_locale}/blog/blogpost/{ContentfulArticle.slug}.js" /* webpackChunkName: "component---src-pages-contentful-article-node-locale-blog-blogpost-contentful-article-slug-js" */),
  "component---src-pages-contentful-author-node-locale-blog-authors-contentful-author-slug-js": () => import("./../../../src/pages/{ContentfulAuthor.node_locale}/blog/authors/{ContentfulAuthor.slug}.js" /* webpackChunkName: "component---src-pages-contentful-author-node-locale-blog-authors-contentful-author-slug-js" */),
  "component---src-pages-contentful-page-node-locale-contentful-page-slug-js": () => import("./../../../src/pages/{ContentfulPage.node_locale}/{ContentfulPage.slug}.js" /* webpackChunkName: "component---src-pages-contentful-page-node-locale-contentful-page-slug-js" */),
  "component---src-pages-fr-preferences-js": () => import("./../../../src/pages/fr/preferences.js" /* webpackChunkName: "component---src-pages-fr-preferences-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-preferenze-js": () => import("./../../../src/pages/it/preferenze.js" /* webpackChunkName: "component---src-pages-it-preferenze-js" */),
  "component---src-pages-preferences-js": () => import("./../../../src/pages/preferences.js" /* webpackChunkName: "component---src-pages-preferences-js" */)
}

