import React from 'react';
import { QueryParamProvider } from 'use-query-params';

import { ViewportProvider } from './src/contexts/providers/ViewportProvider';
import { LanguageProvider } from './src/contexts/providers/LanguageProvider';
import { NavbarProvider } from './src/contexts/providers/NavbarProvider';
import { BreadcrumbProvider } from './src/contexts/providers/BreadcrumbProvider';
import { SitePathsProvider } from './src/contexts/providers/SitePathsProvider';
import { PreferencesProvider } from './src/contexts/providers/PreferencesProvider';
import './src/assets/stylesheets/app.scss';
import { CookieConsent } from './src/utils/cookie-consent';
import {
  getLangKeyFromUrl,
  LANG_KEY_TO_LANGUAGE_MAPPING,
} from './src/utils/languages';
import { TagManagement } from './src/utils/tag-management';

export const wrapRootElement = ({ element }) => (
  <LanguageProvider>
    <NavbarProvider>
      <ViewportProvider>
        <QueryParamProvider>
          <BreadcrumbProvider>
            <SitePathsProvider>
              <PreferencesProvider>{element}</PreferencesProvider>
            </SitePathsProvider>
          </BreadcrumbProvider>
        </QueryParamProvider>
      </ViewportProvider>
    </NavbarProvider>
  </LanguageProvider>
);

export const onInitialClientRender = () => {
  const { initCookieConsent } = CookieConsent();
  const { initTagManagement } = TagManagement();
  initCookieConsent();
  initTagManagement();
};

export const onRouteUpdate = ({ location, loadPageSync }) => {
  const { sendPageView } = TagManagement();

  const res = loadPageSync(location.pathname + location.search);
  if (!res) {
    return null;
  }
  const { data } = res.json;

  const hasDataLayerData = () => data?.content?.dataLayer;
  const isArticle = () => data?.content?.author;
  const isAuthor = () => data?.authors;
  const isPreferencePage = () => data?.content?.name === 'preferences';

  const lang = LANG_KEY_TO_LANGUAGE_MAPPING.get(
    getLangKeyFromUrl(location.pathname)
  );

  if (hasDataLayerData()) {
    const { title, category, subCategory } = data.content.dataLayer;
    return sendPageView({ title, category, subCategory, lang });
  }

  if (isAuthor()) {
    return sendPageView({
      title: 'Authors page',
      category: 'blog',
      subCategory: 'author',
      lang,
    });
  }

  if (isArticle()) {
    return sendPageView({
      title: 'Articles page',
      category: 'blog',
      subCategory: 'article',
      lang,
    });
  }

  if (isPreferencePage()) {
    return sendPageView({
      title: 'Email preferences page',
      category: 'email preferences',
      lang,
    });
  }

  return sendPageView();
};
