import React, { useReducer } from 'react';

import { PreferencesContext } from '../preferences';

import {
  SWITCH_PREFERENCES,
  SWITCH_PREFERENCE,
  SET_PREFERENCES,
  SET_STEP,
} from '../../utils/variables';

export function PreferencesProvider({ children }) {
  const initialState = {
    allOptIn: false,
    allOptOut: true,
    isError: false,
    isLoading: false,
    email: '',
    marketInsights: false,
    productReleases: false,
    smarterShipping: false,
    convelioInsider: false,
    directContacts: false,
    step: 0,
    reasonForUnsubscribe: '',
    tellUsMore: '',
  };

  const isAllOptIn = (state) =>
    state.marketInsights &&
    state.productReleases &&
    state.smarterShipping &&
    state.convelioInsider &&
    state.directContacts;

  const isAllOptOut = (state) =>
    !state.marketInsights &&
    !state.productReleases &&
    !state.smarterShipping &&
    !state.convelioInsider &&
    !state.directContacts;

  const switchPreferences = (state) => {
    const { allOptIn, allOptOut } = state;
    return {
      ...state,
      allOptIn: !allOptIn,
      allOptOut: !allOptOut,
      marketInsights: !allOptIn,
      productReleases: !allOptIn,
      smarterShipping: !allOptIn,
      convelioInsider: !allOptIn,
      directContacts: !allOptIn,
    };
  };

  const switchPreference = (state, payload) => {
    const newState = {
      ...state,
      [payload]: !state[payload],
    };
    return {
      ...newState,
      allOptIn: isAllOptIn(newState),
      allOptOut: isAllOptOut(newState),
    };
  };

  const setPreferences = (state, payload) => {
    const { name, value } = payload;
    return {
      ...state,
      [name]: value,
    };
  };

  const setStep = (state, payload) => {
    const isStepWithinRange =
      state.step + payload >= 0 || state.step + payload < 2;
    return isStepWithinRange ? { ...state, step: payload } : state;
  };

  function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
      case SWITCH_PREFERENCES:
        return switchPreferences(state);
      case SWITCH_PREFERENCE:
        return switchPreference(state, payload);
      case SET_PREFERENCES:
        return setPreferences(state, payload);
      case SET_STEP:
        return setStep(state, payload);
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PreferencesContext.Provider value={{ state, dispatch }}>
      {children}
    </PreferencesContext.Provider>
  );
}
