import React, { useState } from 'react';

import { BreadcrumbContext } from '../breadcrumb';

export const BreadcrumbProvider = ({ children }) => {
  const [customCrumbs, setCustomCrumbs] = useState([]);

  return (
    <BreadcrumbContext.Provider
      value={{
        customCrumbs,
        setCustomCrumbs
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};
