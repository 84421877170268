// See https://github.com/webpack/webpack/issues/14800
/* eslint-disable prefer-destructuring */
export const CUSTOMIO_URL = process.env.CUSTOMIO_URL;
export const AXEPTIO_CLIENT_ID = process.env.AXEPTIO_CLIENT_ID;
export const POSTHOG_API_KEY = process.env.POSTHOG_API_KEY;
export const FACEBOOK_PIXEL_ID = process.env.FACEBOOK_PIXEL_ID;
export const LINKEDIN_INSIGHT_ID = process.env.LINKEDIN_INSIGHT_ID;
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;
export const GOOGLE_ADS_ID = process.env.GOOGLE_ADS_ID;
/* eslint-enable prefer-destructuring */
