const billingCountryCodes = ['fr', 'it', 'en'];

export const LANG_KEY_TO_COUNTRY_LABEL_MAPPING = new Map([
  ['en-US', 'United States'],
  ['en-GB', 'United Kingdom'],
  ['fr', 'France'],
  ['it', 'France'],
  ['en', 'France'],
]);

export const LANG_KEY_TO_LANGUAGE_MAPPING = new Map([
  ['en-US', 'en'],
  ['en-GB', 'en'],
  ['en', 'en'],
  ['fr', 'fr'],
  ['it', 'it'],
]);

export const LANG_KEY_TO_LANGUAGE_LABEL_MAPPING = new Map([
  ['en-US', 'English'],
  ['en-GB', 'English'],
  ['en', 'English'],
  ['fr', 'Français'],
  ['it', 'Italiano'],
]);

export const getLangKeyFromUrl = (pathname) => {
  const predicate = pathname.toLowerCase().trim();

  if (predicate.includes('/en-us/')) {
    return 'en-US';
  }

  if (predicate.includes('/en-gb/')) {
    return 'en-GB';
  }

  if (predicate.includes('/it/')) {
    return 'it';
  }

  if (predicate.includes('/fr/')) {
    return 'fr';
  }

  return 'en';
};

export const getRemainingLanguages = (languages, currentLangKey) =>
  languages.filter((l) => l !== currentLangKey);

export const getLangKeyFromBrowserSettings = (languages) => {
  const browserLanguage = navigator.language || navigator.userLanguage;
  return languages.langs.includes(browserLanguage) ? browserLanguage : 'en';
};

export const keepOnlyBillingCountry = (node_locale, langKey) => {
  if (billingCountryCodes.includes(langKey)) {
    return !billingCountryCodes.includes(node_locale);
  }
  if (['en-US', 'en-GB'].includes(langKey)) {
    return !['it', 'en'].includes(node_locale);
  }
  return true;
};

export const keepOnlyEligibleLanguages = (node_locale, langKey) => {
  return (
    node_locale !== langKey &&
    node_locale !== 'en-US' &&
    node_locale !== 'en-GB' &&
    (langKey === 'en-US' || langKey === 'en-GB' ? node_locale !== 'en' : true)
  );
};
